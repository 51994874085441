import { isSSR } from '../utils/constants';

const KEY_AUTH_TOKEN = 'can-token';

export const setAuthToken = ({ token }) => {
  if (!isSSR) {
    window.localStorage.setItem(KEY_AUTH_TOKEN, token);
  }
};

export const getAuthToken = () => {
  if (!isSSR) {
    return window.localStorage.getItem(KEY_AUTH_TOKEN);
  }
  return null;
};

export const removeAuthToken = () => {
  if (!isSSR) {
    window.localStorage.removeItem(KEY_AUTH_TOKEN);
  }
};

export const isLoggedIn = () => {
  return !!getAuthToken();
};

export const logout = () => {
  if (!isSSR) {
    window.localStorage.removeItem(KEY_AUTH_TOKEN);
    window.location.reload();
  }
};
