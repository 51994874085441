export const isSSR = typeof window === 'undefined';

export const PAGES_TYPES = {
  ART: 'ART',
  CONTACT_US: 'CONTACT_US',
  IMPACT: 'IMPACT',
  LIBRARY: 'LIBRARY',
  MAP_MATCHMAKER: 'MAP_MATCHMAKER',
  TOOLS: 'TOOLS',
};

export const NEWSLETTER_LINK_PROPS = {
  href: 'https://5fcb60a5.sibforms.com/serve/MUIEAKaP3_Z2IKkdcRuZOXmfcKP13umesozFkRUK9B9m-fJZYh3sIEuHP_zeDDynxXsWZO2PsTqmFappuIUUwpoGK9-PvcP75idZHtLSqNrCFLg2UujY4UlaTUZhlM_BnsJ10t52Xwkv34W_iAk6-1Y4Q-A31UGjz140afWtWDE1dQUu0CWU3em9sRef9Owbvmy8FQyenDoXnX-Z',
  target: '_blank',
  rel: 'noopener noreferrer',
};
