import { COLORS } from '../../../utils/theme';

export const CATEGORY_COLOR = [COLORS.BLUE, COLORS.RED, COLORS.PINK, COLORS.PURPLE];
export const CATEGORY_TEXT_COLOR_COMBINATION = [
  COLORS.WHITE,
  COLORS.WHITE,
  COLORS.BLACK,
  COLORS.WHITE,
];

export const CATEGORY_COLOR_BY_LABEL = {
  'Power of Arts': COLORS.BLUE,
  Stories: COLORS.RED,
  'Artfulness Series': COLORS.PINK,
  'Network Highlights': COLORS.PURPLE,
};

export const CATEGORIES_TYPES = {
  ECOSYSTEM: 'ecosystem',
  IMPACT_STORIES: 'impact-stories',
  ARTFULNESS_SERIES: 'artfulness-series',
  BURSTING_THE_BUBBLE: 'bursting-the-buble',
};
