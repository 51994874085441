import axios from 'axios';
import { buildAPIURL } from '../utils/urls';
import {
  CATEGORY_COLOR,
  CATEGORY_TEXT_COLOR_COMBINATION,
} from '../components/Impact/Categories/constants';
import { getAuthToken, removeAuthToken } from '../lib/local-storage';

export const contentFetch = ({
  pathname,
  data = {},
  transform,
  setState,
  method = 'get',
  query = '',
}) => {
  const url = buildAPIURL(pathname, query);
  const authToken = getAuthToken();
  const headers = authToken
    ? {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    : null;

  const axiosMethod = axios[method] || axios;

  const args = method === 'post' ? [url, data, headers] : [url, headers];

  return axiosMethod(...args)
    .then(({ data: response, statusCode }) => {
      const transformedData = transform ? transform(response) : response;
      if (setState) setState(transformedData);
      return transformedData;
    })
    .catch((err) => {
      if (setState) setState(false);
      if (err.response.status === 401) {
        removeAuthToken();
      }
    });
};

export const transformToOptionsSelect = (result = []) =>
  result.map((item) => ({ value: item._id, label: item.label }));

export const transformToContactMember = (result = []) =>
  result.map((item) => ({ ...item, firstName: item.name, organizationName: item.role }));

export const transformToTedTalk = (result = []) =>
  result.map((item) => ({
    ...item,
    artists: item.artists.map((artist) => ({
      name: `${artist.firstName} ${artist.lastName}`,
    })),
  }));

export const transformToCategory = (result = []) =>
  result.map((item, index) => ({
    ...item,
    color: CATEGORY_COLOR[index],
    textColorForBg: CATEGORY_TEXT_COLOR_COMBINATION[index],
    text: item.name,
  }));
